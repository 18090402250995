import i18n from 'i18next'
import en from './locales/en.json'
import tr from './locales/tr.json'
import cn from './locales/cn.json'

import { DEFAULT_LANGUAGE } from 'constants/translations'

const options = {
  interpolation: {
    escapeValue: false
  },

  debug: false,

  resources: {
    en: {
      common: en.en
    },
    tr: {
      common: tr.tr
    },
    cn: {
      common: cn.cn
    }
  },

  fallbackLng: DEFAULT_LANGUAGE,

  ns: ['common'],

  defaultNS: 'common',

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  }
}

const CustomLanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    callback(DEFAULT_LANGUAGE);
  },
  init: () => {},
  cacheUserLanguage: () => {},
}

i18n.use(CustomLanguageDetector).init(options)

export default i18n
